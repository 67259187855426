import React, { useEffect } from 'react';

const Asset = () => {
  useEffect(() => {
    if (window.location) {
      const query = window.location.search;
      window.location.href = '/api/asset' + query;
    }
  }, []);

  return (
    <div>Redirecting...</div>
  );
};

export default Asset;
